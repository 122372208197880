// import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import {auth} from '@/api-services'

// Vue.use(VueRouter)

const redirectToRoot = (to, from, next)=>{
  if (window.$cookies.get('auth')) {
    next({name: 'root-path'})
  } else {
    next()
  }
}

const requireAuthenticated = (to, from, next) => {
  if (!window.$cookies.get('auth')) {
    next({name: 'SignIn', query: {
      redirect: to.fullPath,
   }})
  } else {
    next()
  }
}

const authLawyer = (to, from, next) => {
  if (window.$cookies.get('auth')) {
    auth.getUser().then((res) => {
      if (res.status == 200) {
        if (res.data.role.name == 'lawyer') {
          next()
        } else {
          next({name: 'NotFound'})
        }
      } else {
        next()
      }
    })
  } else {
    next({name: 'root-path'})
  }
}

const authAdmin = (to, from, next) => {
  if (window.$cookies.get('auth')) {
    auth.getUser().then((res) => {
      if (res.status == 200) {
        if (res.data.role.name == 'user') {
          next({name: 'NotFound'})
        } else if (res.data.role.name == 'lawyer') {
          next({name: 'NotFound'})
        } else {
          next()
        }
      } else {
        next()
      }
    })
  } else {
    next({name: 'root-path'})
  }
}

const checkAdmin = (to, from, next) => {
  if (window.$cookies.get('auth')) {
    auth.getUser().then((res) => {
      if (res.status == 200) {
        if (res.data.role.name != 'user' && res.data.role.name != 'lawyer') {
          next({name: 'MainDashboard'})
        } else {
          next()
        }
      } else {
        next()
      }
    })
  } else {
    next()
  }
}
export default createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {top: 0}
    }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'root-path',
      component: () => import('./components/LandingPage/LandingPage.vue'),
      beforeEnter: checkAdmin,
    },
    {
      path: '/SignIn',
      name: 'SignIn',
      beforeEnter: redirectToRoot,
      component: () => import('./pages/LoginPage/LoginPage.vue'),
    },
    {
      path: '/SignUpChooseUser',
      name: 'SignUpChooseUser',
      beforeEnter: redirectToRoot,
      component: () => import('./pages/SignUpPage/SignUpChooseUserPage.vue'),
    },
    {
      path: '/SignUpUser',
      name: 'SignUpUser',
      component: () => import('./pages/SignUpPage/SignUpUserPage.vue'),
    },
    {
      path: '/SignUpLawyer',
      name: 'SignUpLawyer',
      component: () => import('./pages/SignUpPage/SignUpLawyerPage.vue'),
    },
    {
      path: '/my-asked-question',
      name: 'MyAskedQuestions',
      component: () => import('./pages/AuthorizedPages/QuestionPage/MyAskedQuestionPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/update-my-asked-question',
      name: 'UpdateMyAskedQuestion',
      component: () => import('./pages/AuthorizedPages/QuestionPage/EditMyAskedQuestionPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/review-question',
      name: 'ReviewQuestion',
      component: () => import('./pages/AuthorizedPages/QuestionPage/ReviewQuestionPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/Success',
      name: 'Success',
      component: () => import('./pages/AuthorizedPages/QuestionPage/SuccessQuestionPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/password-reset',
      name: 'ResetPassword',
      component: () => import('./pages/ForgetPasswordPage/ForgetPasswordPage.vue'),
    },
    {
      path: '/resend-confirmation',
      name: 'ResendConfirmation',
      component: () => import('./pages/AuthorizedPages/ResendConfirmationPage/ResendConfirmationPage.vue'),
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: () => import('./pages/AuthorizedPages/ChangePasswordPage/ChangePasswordPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/LawyerScreen',
      name: 'LawyerScreen',
      component: () => import('./components/LawyerScreen/LawyerScreen.vue'),
      beforeEnter: authLawyer,
    },
    {
      path: '/setting',
      name: 'Setting',
      component: () => import('./pages/AuthorizedPages/SettingPage/SettingPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/review',
      name: 'Review',
      component: () => import('./pages/ReviewPage/ReviewedLawyersPage.vue'),
    },
    {
      path: '/review/create',
      name: 'ReviewLawyer',
      component: () => import('./pages/AuthorizedPages/ReviewPage/CreateReviewPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/LawyerDashboard',
      name: 'LawyerDashboard',
      component: () => import('./pages/AuthorizedPages/DashboardPage/LawyerDashboardPage.vue'),
      beforeEnter: authLawyer,
    },
    {
      path: '/lawyer/:id/show',
      name: 'ShowLawyer',
      component: () => import('./pages/LawyerPage/ShowLawyerPage.vue'),
    },
    {
      path: '/lawyer-profile',
      name: 'LawyerProfile',
      component: () => import('./pages/AuthorizedPages/LawyerPage/ProfileLawyerPage.vue'),
      beforeEnter: authLawyer,
    },
    {
      path: '/question',
      name: 'ListQuestion',
      component: () => import('./pages/QuestionPage/ListQuestionPage.vue'),
    },
    {
      path: '/question/:id',
      name: 'QuestionDetail',
      component: () => import('./pages/QuestionPage/ShowQuestionPage.vue'),
    },
    {
      path: '/recently-asked-question',
      name: 'RecentlyAskedQuestion',
      component: () => import('./pages/AuthorizedPages/QuestionPage/RecentlyAskedQuestionPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    // TODO(Saroj Dahal): Need to Refactor this to resolve ambiguity among specialization, district and both
    {
      path: '/lawyer-list',
      name: 'ListSearchResult',
      component: () => import('./pages/LawyerPage/ListSearchLawyerPage.vue'),
    },
    {
      path: '/AboutUs',
      name: 'AboutUs',
      component: () => import('./components/Misc/AboutUs.vue'),
    },
    {
      path: '/ContactUs',
      name: 'ContactUs',
      component: () => import('./pages/ContactUsPage/ContactUsPage.vue'),
    },
    {
      path: '/FAQ',
      name: 'FAQ',
      component: () => import('./components/Misc/FAQ.vue'),
    },
    {
      path: '/PrivacyPolicy',
      name: 'PrivacyPolicy',
      component: () => import('./components/Misc/PrivacyPolicy.vue'),
    },
    {
      path: '/TOS',
      name: 'TOS',
      component: () => import('./components/Misc/TOS.vue'),
    },
    {
      path: '/notification',
      name: 'Notification',
      component: () => import('./pages/AuthorizedPages/NotificationPage/NotificationPage.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/ListingByCity',
      name: 'ListingByCity',
      component: () => import('./components/ListingPages/ListingByCity.vue'),
    },
    {
      path: '/ListingBySpecialization',
      name: 'ListingBySpecialization',
      component: () => import('./components/ListingPages/ListingBySpecialization.vue'),
    },
    {
      path: '/QuestionAsked/:id',
      name: 'QuestionAsked',
      component: () => import('./components/ListingPages/QuestionAsked.vue'),
      beforeEnter: requireAuthenticated,
    },
    {
      path: '/MainDashboard',
      name: 'MainDashboard',
      component: () => import('./pages/AuthorizedPages/DashboardPage/AdminDashboardPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/lawyer',
      name: 'ListLawyer',
      component: () => import('./pages/AuthorizedPages/LawyerPage/ListLawyerPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin/lawyer/:id/show',
      name: 'ShowAdminLawyer',
      component: () => import('./pages/AuthorizedPages/LawyerPage/ShowLawyerPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin/lawyer/:id',
      name: 'UpdateAdminLawyer',
      component: () => import('./pages/AuthorizedPages/LawyerPage/UpdateLawyerPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/role',
      name: 'ListRole',
      component: () => import('./pages/AuthorizedPages/RolesPermissionPage/ListRolesPermissionPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/role/create',
      name: 'CreateRole',
      component: () => import('./pages/AuthorizedPages/RolesPermissionPage/CreateRolePage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/permission/create',
      name: 'CreatePermission',
      component: () => import('./pages/AuthorizedPages/RolesPermissionPage/CreatePermissionPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/role-permission/create',
      name: 'CreateRolePermission',
      component: () => import('./pages/AuthorizedPages/RolesPermissionPage/CreateRolesPermissionPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/user',
      name: 'ListUser',
      component: () => import('./pages/AuthorizedPages/UserPage/ListUserPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/user/create',
      name: 'CreateUser',
      component: () => import('./pages/AuthorizedPages/UserPage/CreateUserPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin',
      name: 'AdminLogin',
      component: () => import('./components/Admin/AdminLogin.vue'),
    },
    {
      path: '/admin/forgotpassword',
      name: 'AdminForgotPasswprd',
      component: () => import('./components/Admin/AdminForgotPassword.vue'),
    },
    {
      path: '/testimonial/create',
      name: 'CreateTestimonial',
      component: () => import('./pages/AuthorizedPages/TestimonialPage/CreateTestimonialPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/testimonial/:id',
      name: 'UpdateTestimonial',
      component: () => import('./pages/AuthorizedPages/TestimonialPage/EditTestimonialPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/testimonial',
      name: 'ListTestimonials',
      component: () => import('./pages/AuthorizedPages/TestimonialPage/ListTestimonialPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin/question',
      name: 'ListAdminQuestion',
      component: () => import('./pages/AuthorizedPages/QuestionPage/ListQuestionPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin/question/:id',
      name: 'EditQuestion',
      component: () => import('./pages/AuthorizedPages/QuestionPage/EditQuestionPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/QAList/:id',
      name: 'QAList',
      component: () => import('./components/Admin/Question/QAList.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/service',
      name: 'ListService',
      component: () => import('./pages/ServicesPage/ListServicePage.vue'),
    },
    {
      path: '/admin/service',
      name: 'AdminListServices',
      component: () => import('./pages/AuthorizedPages/ServicePage/ListServicePage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin/solicitation',
      name: 'AdminListSolicitation',
      component: () => import('./pages/AuthorizedPages/ServicePage/ListSolicitationPage.vue'),
    },
    {
      path: '/nrn-service',
      name: 'NRNService',
      component: () => import('./pages/ServicesPage/NRNServicePage.vue'),
    },
    {
      path: '/admin/nrn-solicitation',
      name: 'AdminListNRNSolicitation',
      component: () => import('./pages/AuthorizedPages/NRNServicePage/ListNRNSolicitationPage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/admin/service-notice',
      name: 'ServiceNotice',
      component: () => import('./pages/AuthorizedPages/ServiceNoticePage/ListServiceNoticePage.vue'),
      beforeEnter: authAdmin,
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import('./pages/NotFoundPage/NotFoundPage.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ],
})
